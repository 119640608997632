import React from 'react';
import TopNavBar from '../components/TopNavBar';
import Footer from '../components/Footer';
import PresentationTab from '../components/PresentationTab';


function Home() {

    var words = ["Pentru un zâmbet perfect", "Calitate garantată la un preț corect", "Cele mai bune servicii dentare"];
    let currentWord = -1;

    window.setInterval(function() {
        const element = document.getElementById("slogan");

        if (element) {
            currentWord++;
            if (currentWord >= words.length){
                currentWord = 0;
            }
            element.innerHTML = "" + words[currentWord];
        }
    }, 4000);

    return (
        <div className="flex-wrapper">
         
            <div className='bg-image container-header'>
                <div className="blur-background"></div>
                <TopNavBar />
                <div className="cover-title text-center">
                    <h1 className="title text-center"> Bun venit la Attica Dent </h1>
                    <p className="sub-title text-center" id="slogan">Grijă pentru pacienți</p>
                </div>
            </div>
                
                
                

                {/* <div className="row promisiune-section">
                    <div className="col-md-6 col-12">
                        <h2>Promisiunea noastra pentru fiecare pacient</h2>
                    </div>
                    <div className="col-md-6 col-12">
                        <p>Aceasta este promisiunea noastra pentru fiecare pacient care ne trece pragul</p>
                    </div>
                </div> */}
            <div className="container">
                <PresentationTab />
                
                {/* <div className="row servicii-section">
                    <div className="col-md-6 col-12">
                     <img src={cabinetImage} class="image-home rounded mx-auto d-block" alt="..." />
                    </div>
                    <div className="col-md-6 col-12">
                        <h3 className="text-center">Serviciile Attica Dent</h3>
                        <p className="text-center">Va oferim o experienta deosebita care va va oferi zambetul perfect prin odontoterapie,
                        profilaxie, endodontie, protetica</p>
                    </div>
                </div> */}

                <div className="quote-main text-center">
                    <h3 className="text-center font-lora-bold">Încredințează-ți zâmbetul unui medic dedicat sănătății tale dentare!</h3>
                    <a href="tel:0767071622">
                        <button className="button-cta-programare">Programare telefonică </button>
                    </a> 
                </div>
            </div>
            <Footer />
        </div>    
    )
}

export default Home;