import React, {useState} from 'react';
import {CButton, CCollapse, CCard, CCardBody} from '@coreui/react';

function Endodontie() {
const [visible, setVisible] = useState(false);

    return (
       <div className="text-center margin-top-20">
        <p className="sub-title text-center">Endodontie</p>
        <CButton className="button-price" onClick={() => setVisible(!visible)}>Mai mult</CButton>
        <CCollapse visible={visible}>
                <CCard className="mt-3">
                    <CCardBody>
                    <p className="text-now-page text-center">Extirpare vitala monoradicular 200 lei</p> 
                    <p className="text-now-page text-center">Extirpare vitala biradicular 250 lei</p>
                    <p className="text-now-page text-center">Extirpare vitala pluriradicular  300 lei</p>
                    <p className="text-now-page text-center">Pansament ciment 100 lei</p> 
                    <p className="text-now-page text-center">Drenaj edodontic  150 lei</p> 
                    <p className="text-now-page text-center">Pansament calmant  100 lei</p> 
                    <p className="text-now-page text-center">Obturatie provizorie cu hidroxid de calciu 100 lei</p> 
                    <p className="text-now-page text-center">Obturatie definitiva monoradicular 150 lei</p> 
                    <p className="text-now-page text-center">Obturatie definitive biradicular 200 lei</p> 
                    <p className="text-now-page text-center">Obturatie definitiva pluriradicular 280 lei</p> 
                    <p className="text-now-page text-center">Tratament de canal si obturatie intr-o sedinta
                    monoradicular 350 lei</p> 
                    <p className="text-now-page text-center">Tratament de canal si obturatie intr-o sedinta
                    biradicular 460 lei</p> 
                    <p className="text-now-page text-center">Tratament de canal si obturatie intr-o sedinta
                    pluriradicular 580 lei</p> 
                    <p className="text-now-page text-center">Retratament dinte monoradicular 400 lei</p> 
                    <p className="text-now-page text-center">Retratament dinte biradicular 500 lei</p> 
                    <p className="text-now-page text-center">Retratament dinte pluriradicular 650 lei</p> 
                    <p className="text-now-page text-center">Indepartare pivot 280 lei</p> 
                    <p className="text-now-page text-center">Albire endodontica pe sedinta 280 lei</p>
                    <p className="text-now-page text-center">Reconstuctie bont dentar cu pivot fibra de sticla 280 lei</p>
                    <p className="text-now-page text-center">Extractie molar 300 lei</p>
                    <p className="text-now-page text-center">Extractie premolar 250 lei</p>
                    <p className="text-now-page text-center">Extractie incisiv 200 lei</p>
                    </CCardBody>
                </CCard>
        </CCollapse>
       </div> 
        
    )
}

export default Endodontie;