import React from 'react';
import TopNavBar from '../components/TopNavBar';
import Footer from '../components/Footer';

function Servicii() {
    return (
        <div className="flex-wrapper">
            <div className="container">
                <TopNavBar />
                <h1 className="title-price text-center">Servicii</h1>
            </div>
            <Footer />
        </div>        
    )
};

export default Servicii;