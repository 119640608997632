import React, {useState} from 'react';
import {CButton, CCollapse, CCard, CCardBody} from '@coreui/react';

function Pedodontie() {
const [visible, setVisible] = useState(false);

    return (
       <div className="text-center margin-top-20">
        <p className="sub-title text-center">Pedodontie </p> 
        <CButton className="button-price" onClick={() => setVisible(!visible)}>Mai mult</CButton> 
        <CCollapse visible={visible}>
                <CCard className="mt-3">
                    <CCardBody>
                        <p className="text-now-page text-center">Consultatie pediatrica (sub 3 ani) 100 lei</p> 
                        <p className="text-now-page text-center">Consultatie pediatrica (peste 3 ani) 150 lei</p>
                        <p className="text-now-page text-center">Periaj profesional si airflow 200 lei</p>
                        <p className="text-now-page text-center">Periaj profesional si fluorizare 200 lei</p> 
                        <p className="text-now-page text-center">Fluorizare/arcada 80 lei</p> 
                        <p className="text-now-page text-center">Obturatie CIS o suprafata 180 lei</p> 
                        <p className="text-now-page text-center">Obturatie CIS doua suprafate 200 lei</p> 
                        <p className="text-now-page text-center">Obturatie CIS profunda 220 lei</p> 
                        <p className="text-now-page text-center">Obturatie compozit o suprafata 200 lei</p> 
                        <p className="text-now-page text-center">Obturatie compozit doua suprafate 230 lei</p>
                        <p className="text-now-page text-center">Obturatie compozit profunda 260 lei</p>
                        <p className="text-now-page text-center">Extractie dinte temporar mobil 110 lei</p>
                        <p className="text-now-page text-center">Extractie dinte temporar fara mobilitate 180 lei</p>
                        <p className="text-now-page text-center">Extractie rest radicular dinte temporar 170 lei</p>
                        <p className="text-now-page text-center">Drenaj dinte temporar si tratament de urgenta 160 lei</p>
                        <p className="text-now-page text-center">Tratament gangrena 230 lei</p>
                        <p className="text-now-page text-center">Tratament devitalizat dinte temporar 150 lei</p>
                        <p className="text-now-page text-center">Tratament endodontic dinte temporar monoradicular 270 lei</p>
                        <p className="text-now-page text-center">Tratament endodontic dinte temporar pluriradicular 310 lei</p>
                        <p className="text-now-page text-center">Tratament medicamentos dinte temporar/sedinta 100 lei</p>
                        <p className="text-now-page text-center">Coroana metalica dinte temporar 450 lei</p>
                        <p className="text-now-page text-center">Coroana provizorie PMMA temporar 380 lei</p>
                        <p className="text-now-page text-center">Tratamentul cariei incipiente icon dinte permanent 250 lei</p>
                        <p className="text-now-page text-center">Sigilare dinte temporar 100 lei</p>
                        <p className="text-now-page text-center">Imobilizare dinte permanent post-traumatism 250 lei</p>
                    </CCardBody>
                </CCard>
        </CCollapse>
       </div> 
        
    )
}

export default Pedodontie;