import React, {useState} from 'react';
import {CButton, CCollapse, CCard, CCardBody} from '@coreui/react';

function Protetica() {
const [visible, setVisible] = useState(false);

    return (
       <div className="text-center margin-top-20">
        <p className="sub-title text-center margin-left-">Protetica</p>
        <CButton className="button-price text-center" onClick={() => setVisible(!visible)}>Mai mult</CButton>
        <CCollapse visible={visible}>
                <CCard className="mt-3">
                    <CCardBody>
                    <p className="text-now-page text-center">Metalic ni-cr/co-cr 250 lei / 300 lei</p> 
                    <p className="text-now-page text-center">Fibra sticla 300 lei</p> 
                    <p className="text-now-page text-center">Zirconiu 350 lei</p> 
                    <p className="text-now-page text-center">Coroana acrilat 250 lei</p>
                    <p className="text-now-page text-center">Coroana metalo-ceramica total fizionomica ni-cr 700 lei</p>  
                    <p className="text-now-page text-center">Coroana metalo-ceramica total fizionomica co-cr 750 lei</p>  
                    <p className="text-now-page text-center">Coroana ceramic pe zirconiu 1200 lei</p>  
                    <p className="text-now-page text-center">Coroana full zirconiu 1200 lei</p>
                    <p className="text-now-page text-center">Coroana weisser zirconiu 950 lei</p>
                    <p className="text-now-page text-center">Coroana e-max 1500 lei</p>
                    <p className="text-now-page text-center">Coroana turnata (metalica) 300 lei</p>
                    <p className="text-now-page text-center">Coroana metalo-ceramica pe implant 1300 lei</p>
                    <p className="text-now-page text-center">Coroana ceramic zirconiu pe implant 1700 lei</p>
                    <p className="text-now-page text-center">Coroana acrilica provizorie 200 lei</p>
                    <p className="text-now-page text-center">Proteza partiala acrilica 1500 lei</p>
                    <p className="text-now-page text-center">Proteza totala acrilica 1600 lei</p>
                    <p className="text-now-page text-center">Proteza biodentaplast 2700 lei</p>
                    <p className="text-now-page text-center">Proteza scheletata – sisteme speciale De la 2000 lei + sisteme special</p>
                    <p className="text-now-page text-center">Proteza unilaterala – sistem special 1500 lei + sistem special</p>
                    <p className="text-now-page text-center">Proteza pe implanturi cu sistem de capsa/bara 4600 lei</p>
                    <p className="text-now-page text-center">Proteza pe implanturi cu sistem multiunit 6250 lei</p>
                    <p className="text-now-page text-center">Reparatie proteza 200 lei</p>
                    <p className="text-now-page text-center">Captusire proteza 260 lei</p>
                    <p className="text-now-page text-center">Adaugare dinte pe proteza 160 lei</p>
                    <p className="text-now-page text-center">Reparatie croset 160 lei</p>
                    <p className="text-now-page text-center">Proteza kemeny (1-3) 300 lei</p>
                    <p className="text-now-page text-center">Ablatie (element) 80 lei</p>
                    <p className="text-now-page text-center">Cimentare lucrare/element 60 lei</p>
                    <p className="text-now-page text-center">Inlocuire teflon 200 lei</p>
                    </CCardBody>
                </CCard>
        </CCollapse>
       </div> 
        
    )
}

export default Protetica;