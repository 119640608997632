import React from 'react';
import TopNavBar from '../components/TopNavBar';
import Footer from '../components/Footer';
import cabinetImg from '../assets/bg-image.jpeg';

function DespreNoi() {
    return (
        <div className="flex-wrapper">
            <div className="container">
                <TopNavBar />
                <h1 className="title-price text-center">Despre Noi</h1>
                <div className="row">
                    <div className="col-md-6 col-12">
                        <img src={cabinetImg} className="img-fluid" alt="Poza cabinet stomatologic" />
                    </div>
                    <div className="col-md-6 col-12">
                        <p className="bio text-center">Bine ați venit la Cabinetul Stomatologic Attica Dent! 
                        Suntem o echipă dedicată de profesioniști în domeniul stomatologiei, 
                        centrându-ne pe oferirea celor mai bune servicii pentru sănătatea orală 
                        a pacienților noștri. Cu o abordare personalizată, ne străduim să creăm 
                        un mediu prietenos și relaxant, unde pacienții se simt în siguranță și înțelesi. 
                        Cu tehnologie de ultimă generație și experiență vastă, suntem aici să vă ajutăm 
                        să obțineți un zâmbet sănătos și strălucitor. Așteptăm cu nerăbdare să vă 
                        cunoaștem și să vă oferim cele mai bune soluții pentru nevoile dumneavoastră 
                        stomatologice.</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>        
    )
};

export default DespreNoi;