import React from 'react';
import {
  BrowserRouter as Router,
  Switch, 
  Route
} from "react-router-dom";

import Home from './screens/Home';
import Preturi from './screens/Preturi';
import DespreNoi from './screens/DespreNoi';
import Servicii from './screens/Servicii';
import Contact from './screens/Contact';

import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/preturi" component={Preturi} />
        <Route exact path="/despre-noi" component={DespreNoi} />
        <Route exact path="/servicii" component={Servicii} />
        <Route exact path="/contact" component={Contact} />
      </Switch>
    </Router>
  );
}

export default App;