import React from 'react';
import TopNavBar from '../components/TopNavBar';
import Footer from '../components/Footer';

function Contact() {
    return (
        <div className="flex-wrapper">
            <div className="container">
                <TopNavBar />
                <h1 className="title-price text-center">Contact</h1>
                <p className="bio text-center">Contactează-ne telefonic pentru o programare</p>
                <a href="tel:0767071622">
                        <button className="button-cta-programare">Programare telefonică </button>
                </a> 
                <p className="bio text-center">Contactează-ne pe Whatsapp pentru o programare</p>
                <a href="https://wa.me/+40767071622">
                        <button className="button-cta-programare">Programare pe Whatsapp </button>
                </a> 
            </div>
            <Footer />
        </div>        
    )
};

export default Contact;