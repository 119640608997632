import React, {useState} from 'react';
import {CButton, CCollapse, CCard, CCardBody} from '@coreui/react';

function Profilaxie() {
const [visible, setVisible] = useState(false);

    return (
       <div className="text-center margin-top-20">
        <p className="sub-title text-center">Profilaxie </p> 
        <CButton className="button-price" onClick={() => setVisible(!visible)}>Mai mult</CButton> 
        <CCollapse visible={visible}>
                <CCard className="mt-3">
                    <CCardBody>
                    <p className="text-now-page text-center">Detartraj + periaj profesional 200 lei</p> 
                    <p className="text-now-page text-center">Airflow 100 lei</p>
                    <p className="text-now-page text-center">Igienizare complete (detartraj+periaj+airflow) 270 lei</p>
                    <p className="text-now-page text-center">Gutiera bruxism 250 lei</p> 
                    <p className="text-now-page text-center">Albire in cabinet 750 lei</p> 
                    <p className="text-now-page text-center">Albire la domiciliu (gutiera inclusa) 1000 lei</p> 
                    </CCardBody>
                </CCard>
        </CCollapse>
       </div> 
        
    )
}

export default Profilaxie;