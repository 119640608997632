import React from 'react';
import TopNavBar from '../components/TopNavBar';
import Footer from '../components/Footer';
import Odontoterapie from '../components/Price/Odontoterapie';
import Endodontie from '../components/Price/Endodontie';
import Profilaxie from '../components/Price/Profilaxie';
import Protetica from '../components/Price/Protetica';
import Pedodontie from '../components/Price/Pedodontie';

function Preturi () {
    
    return (
        <div className="flex-wrapper">
            <div className="container">
                <TopNavBar />
                <h1 className="title-price text-center">Preturi</h1>
                
                <Odontoterapie />
                <Endodontie />
                <Profilaxie />
                <Protetica />
                <Pedodontie />
            </div>
            <Footer />
        </div>        
    )
}

export default Preturi;