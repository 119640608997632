import React from 'react';

function Footer() {
    return (
        <div className="new-footer">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-4">
                        <h2 className="footer-title text-center">Contacteaza-ne</h2>
                        <p className="footer-email text-center">0767071622</p>
                        <p className="footer-email text-center">contact@atticadent.ro</p>
                        <div className="social-icons text-center">
                            <ul>
                                <li>
                                    <a href="https://facebook.com/AtticaDent" aria-label="Facebook">
                                        <i className="fa fa-facebook fa-lg" aria-hidden />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://instagram.com/" aria-label="Instagram">
                                        <i className="fa fa-instagram fa-lg" aria-hidden />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://wa.me/+40767071622" aria-label="Whatsapp">
                                        <i className="fa-brands fa-whatsapp fa-lg" aria-hidden />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <h2 className="footer-title text-center">Detalii</h2>
                        <div className="footer-company-list text-center">
                            <ul>
                                <li>
                                    <a href="/despre-noi" className="footer-company-items">Despre noi</a>
                                </li>
                                <li>
                                    <a href="/servicii" className="footer-company-items">Servicii</a>
                                </li>
                                <li>
                                    <a href="/preturi" className="footer-company-items">Preturi</a>
                                </li>
                                <li>
                                    <a href="/contact" className="footer-company-items">Contact</a>
                                </li>
                                <li>
                                    <a href="https://anpc.ro/" className="footer-company-items">ANPC</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <h2 className="footer-title text-center">Adresa</h2>
                        <p className="footer-email text-center">Bucuresti, Sector 6</p>
                        <p className="footer-email text-center">Strada Cernisoara 1, 061011</p>
                    </div>
                </div>
            </div>
            <p className="footer-copyright-text text-center">© 2024 - Attica Dent. Toate drepturile rezervate.</p>
        </div>
    )
}

export default Footer;
