import React, {useState} from 'react';
import {CButton, CCollapse, CCard, CCardBody} from '@coreui/react';

function Odontoterapie() {
const [visible, setVisible] = useState(false);

    return (
       <div className="text-center">
        <p className="sub-title text-center">Odontoterapie (terapia cariei simple)</p>
        <CButton className="button-price" onClick={() => setVisible(!visible)}>Mai mult</CButton>
        <CCollapse visible={visible}>
                <CCard className="mt-3">
                    <CCardBody>
                    <p className="text-now-page text-center">Obturatie o suprafata 250 lei</p> 
                    <p className="text-now-page text-center">Obturatie doua suprafete 300 lei</p>
                    <p className="text-now-page text-center">Obturatie profunda dinte lateral 250-300 lei</p> 
                    <p className="text-now-page text-center">Obturatie pe dinte frontal 200-300 lei</p> 
                    <p className="text-now-page text-center">Obturatie glassionomer 150 lei</p> 
                    <p className="text-now-page text-center">Obturatie equia 200 lei</p> 
                    <p className="text-now-page text-center">Sigilare dinte permanent 130 lei</p> 
                    <p className="text-now-page text-center">Indepartare obturatie mica 30 lei</p> 
                    <p className="text-now-page text-center">Indepartare obturatie mare 40 lei</p> 
                    <p className="text-now-page text-center">Reconstructie perete 120-190 lei</p> 
                    <p className="text-now-page text-center">Protectie pulpara 50 lei</p> 
                    </CCardBody>
                </CCard>
        </CCollapse>
       </div> 
        
    )
}

export default Odontoterapie;