import React from 'react';

function PresentationTab() {
    return (
        <div className='row icons-presentation '>
            <h3 className="text-center font-lora-bold">La Attica Dent ai parte de:</h3>
            <div className="col-md-4 col-xs-12 padding-top-5">
                <p className="text-center icons-subtitle"><i class="fa-solid fa-tooth"></i></p>
                <p className="text-center text-presentation">Garanția calității</p>
            </div>
            <div className="col-md-4 col-xs-12 padding-top-5">
                <p className="text-center icons-subtitle"><i class="fa fa-solid fa-user-doctor"></i></p>
                <p className="text-center text-presentation">Cadre profesioniste</p>
            </div>
            <div className="col-md-4 col-xs-12 padding-top-5">
                <p className="text-center icons-subtitle"><i class="fa-solid fa-kit-medical"></i></p>
                <p className="text-center text-presentation">Materiale calitative</p>
            </div>
        </div>
    )
};

export default PresentationTab;