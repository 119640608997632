import React from 'react';

import {Link} from 'react-router-dom';


function TopNavBar() {
    return (
        <nav className="main-nav">
            <input type="checkbox" id="nav" className="d-none" />
            <label htmlFor="nav" className="nav-btn">
                <i />
                <i />
                <i />
            </label>
            <div className="logo">
                <Link to="/">Attica Dent</Link>
            </div>
            <div className="nav-wrapper">
                <ul>
                    <li>
                        <Link to="/">Acasă</Link>
                    </li>
                    <li>
                        <Link to="/despre-noi">Despre noi</Link>
                    </li>
                    <li>
                        <Link to="/servicii">Servicii</Link>
                    </li>
                    <li>
                        <Link to="/preturi">Prețuri</Link>
                    </li>
                    <li>
                        <Link to="/contact">Contact</Link>
                    </li>
                </ul>
            </div>
        </nav>
    )
}

export default TopNavBar;